import Vue from 'vue'

import type { CurrentUser, NumberOfMembers } from '@/javascript/interfaces'

import { getDefaultCourse } from '../helpers/_constants'
import { getMemberDetails, postNotes } from '../services/administratorDashboard'
import type {
	BackOfficeIntegration,
	EligibleAgency,
	Note,
	NotesData,
} from '../services/administratorDashboard/interfaces'
import api from '../services/api'
import type { Goal, HistoricalTrainingGoal } from '../services/goals/interfaces'
import type { Group } from '../services/groups/interfaces'
import { putUpdateAnnualYear } from '../services/members'
import type { Member, MemberDetails } from '../services/members/interfaces'
import { getAgencyId } from '../utils/getAgencyId'
import GlobalStore from './GlobalStore'
import MemberTrainingStore from './MemberTrainingStore'

declare global {
	interface Window {
		current_user: CurrentUser
	}
}

const state = Vue.observable({
	additionalTraining: {} as HistoricalTrainingGoal,
	agencyHasBirthdayReq: false,
	agencyStateRegistration: false,
	backOfficeIntegration: {
		enabled: false,
		providerName: '',
	} as BackOfficeIntegration,
	backToAdministratorDashboardUrl: '',
	backToAdministratorLinkType: '',
	currentCourseStatusId: null as number | null,
	eligibleAgencies: [] as EligibleAgency[],
	excludeNewTrainingBundleId: null as number | null,
	filteredMemberIds: [] as number[],
	filteredMembers: [] as Member[],
	goal: null as Goal | null,
	goals: [] as Goal[],
	groups: [] as Group[],
	isAdvancedAutomationEnabled: false,
	isHireDateTheBaseDateForComplianceAnnualDueDate: false,
	isLoadingMember: false,
	isSuper: false,
	manageGroups: false,
	memberCount: 0,
	memberCreatedAt: '',
	memberDetails: null as MemberDetails | null,
	memberFilter: '',
	memberList: [] as Member[],
	memberNotes: [] as Note[],
	membersActiveCount: 0,
	notStartedMembers: 0,
	numberOfMembers: {
		dueSoon: 0,
		overdue: 0,
		total: 0,
		untrained: 0,
	} as NumberOfMembers,
	shouldDisplayInProgressMessage: false,
	showDueDate: false,
	updatedAnnualDueDate: {
		date: '',
		id: null as number | null,
	},
})

const store = {
	// Getters and Setters
	get additionalTraining(): HistoricalTrainingGoal {
		return state.additionalTraining
	},
	set additionalTraining(value) {
		state.additionalTraining = value
	},

	get agencyHasBirthdayReq(): boolean {
		return state.agencyHasBirthdayReq
	},
	set agencyHasBirthdayReq(value) {
		state.agencyHasBirthdayReq = value
	},

	get agencyStateRegistration(): boolean {
		return state.agencyStateRegistration
	},
	set agencyStateRegistration(value) {
		state.agencyStateRegistration = value
	},

	get backOfficeIntegration(): BackOfficeIntegration {
		return state.backOfficeIntegration
	},
	set backOfficeIntegration(value) {
		state.backOfficeIntegration = value
	},

	get backToAdministratorDashboardUrl(): string {
		return state.backToAdministratorDashboardUrl
	},
	set backToAdministratorDashboardUrl(value) {
		state.backToAdministratorDashboardUrl = value
	},

	get backToAdministratorLinkType(): string {
		return state.backToAdministratorLinkType
	},
	set backToAdministratorLinkType(value) {
		state.backToAdministratorLinkType = value
	},

	get currentCourseStatusId(): number | null {
		return state.currentCourseStatusId
	},
	set currentCourseStatusId(value) {
		state.currentCourseStatusId = value
	},

	get eligibleAgencies(): EligibleAgency[] {
		return state.eligibleAgencies
	},
	set eligibleAgencies(value) {
		state.eligibleAgencies = value
	},

	get excludeNewTrainingBundleId(): number | null {
		return state.excludeNewTrainingBundleId
	},
	set excludeNewTrainingBundleId(value) {
		state.excludeNewTrainingBundleId = value
	},

	get filteredMemberIds(): number[] {
		return state.filteredMemberIds
	},
	set filteredMemberIds(value) {
		state.filteredMemberIds = value
	},

	get filteredMembers(): Member[] {
		return state.filteredMemberIds?.length ?
			state.memberList.filter(member => state.filteredMemberIds.includes(member.id)) :
			state.memberList
	},

	get goal(): Goal | null {
		return state.goal
	},
	set goal(value) {
		state.goal = value
	},

	get goals(): Goal[] {
		return state.goals
	},
	set goals(value) {
		state.goals = value

		const goal = state.goals.find(goal => !goal.should_be_collapsed)

		if (goal) {
			state.goal = goal
		}
	},

	get groups(): Group[] {
		return state.groups
	},
	set groups(value) {
		state.groups = value
	},

	get isAdvancedAutomationEnabled(): boolean {
		return state.isAdvancedAutomationEnabled
	},
	set isAdvancedAutomationEnabled(value) {
		state.isAdvancedAutomationEnabled = value
	},

	get isHireDateTheBaseDateForComplianceAnnualDueDate(): boolean {
		return state.isHireDateTheBaseDateForComplianceAnnualDueDate
	},
	set isHireDateTheBaseDateForComplianceAnnualDueDate(value) {
		state.isHireDateTheBaseDateForComplianceAnnualDueDate = value
	},

	get isLoadingMember(): boolean {
		return state.isLoadingMember
	},
	set isLoadingMember(value) {
		state.isLoadingMember = value
	},

	get isSuper(): boolean {
		return state.isSuper
	},
	set isSuper(value) {
		state.isSuper = value
	},

	get manageGroups(): boolean {
		return state.manageGroups
	},
	set manageGroups(value) {
		state.manageGroups = value
	},

	get memberCount(): number {
		return state.memberCount
	},
	set memberCount(value) {
		state.memberCount = value
	},

	get memberCreatedAt(): string {
		return state.memberCreatedAt
	},
	set memberCreatedAt(value) {
		state.memberCreatedAt = value
	},

	get memberDetails(): MemberDetails | null {
		return state.memberDetails
	},
	set memberDetails(value) {
		state.memberDetails = value
	},

	get memberFilter(): string {
		return state.memberFilter
	},
	set memberFilter(value) {
		state.memberFilter = value
	},

	get memberList(): Member[] {
		return state.memberList
	},
	set memberList(value) {
		state.memberList = value
	},

	get memberNotes(): Note[] {
		return state.memberNotes
	},
	set memberNotes(value) {
		state.memberNotes = value
	},

	get membersActiveCount(): number {
		return state.membersActiveCount
	},
	set membersActiveCount(value) {
		state.membersActiveCount = value
	},

	get notStartedMembers(): number {
		return state.notStartedMembers
	},
	set notStartedMembers(value) {
		state.notStartedMembers = value
	},

	get numberOfMembers(): NumberOfMembers {
		return state.numberOfMembers
	},
	set numberOfMembers(value) {
		state.numberOfMembers = value
	},

	get shouldDisplayInProgressMessage(): boolean {
		return state.shouldDisplayInProgressMessage
	},
	set shouldDisplayInProgressMessage(value) {
		state.shouldDisplayInProgressMessage = value
	},

	get showDueDate(): boolean {
		return state.showDueDate
	},
	set showDueDate(value) {
		state.showDueDate = value
	},

	get updatedAnnualDueDate(): { date: string, id: number | null } {
		return state.updatedAnnualDueDate
	},
	set updatedAnnualDueDate(value) {
		state.updatedAnnualDueDate = value
	},

	// Actions
	async fetchMemberDetails(memberId: number): Promise<void> {
		state.isLoadingMember = true

		try {
			const memberDetailsUrl = `/agencies/${getAgencyId()}/caregivers/${memberId}/panel`
			const response = await getMemberDetails(api, memberDetailsUrl)

			state.additionalTraining = {
				caregiver_id: memberId,
				courses: response.historical_courses,
				id: null,
			}
			state.agencyHasBirthdayReq = response.agency_has_birthday_req
			state.agencyStateRegistration = response.agency_state_registration
			state.backOfficeIntegration = {
				enabled: response.back_office_integration_enabled ?? false,
				providerName: response.back_office_integration_provider_name ?? '',
			}

			state.memberDetails = response
			state.memberDetails.caregiver = response.caregiver
			state.memberDetails.caregiver_created_at = response.caregiver_created_at
			state.memberDetails.caregiver.notes = response.caregiver.notes ? response.caregiver.notes
				.sort((a: Note, b: Note) =>
					a.created > b.created ? -1 : a.created < b.created ? 1 : 0
				) : []
			state.memberDetails.has_eligible_agencies = response.has_eligible_agencies
			state.memberDetails.historical_courses = response.historical_courses ?? []
			state.memberDetails.is_permitted_user = response.is_permitted_user
			state.memberDetails.paths = response.paths

			if (state.memberDetails.caregiver) {
				state.isSuper = response.is_super
			}

			state.goals = response.caregiver_goals || []
			state.goal = state.goals.length === 1 ? state.goals[0] : state.goal
			state.eligibleAgencies = response.eligible_agencies

			MemberTrainingStore.editCourse = getDefaultCourse()
			MemberTrainingStore.isCertificationsEnabled = response.is_specialized_certifications_feature_enabled
			MemberTrainingStore.waiveTypes = response.waive_types

			GlobalStore.currentUser = {
				...window.current_user,
				canModifyComplianceCurriculum: response.can_modify_compliance_curriculum,
			}
		} catch (error) {
			console.error(error)
		} finally {
			state.isLoadingMember = false
		}
	},

	setCurrentGoal(goalId: number | undefined): void {
		if (!goalId) {
			return
		}

		const goal = state.goals?.find(goal => goal.id === goalId)

		if (!!goal) {
			state.goal = goal
		}
	},

	async updateAnnualYear({
		id, path, year,
	}: { id: number, path: string, year: number }): Promise<void> {
		try {
			const response = await putUpdateAnnualYear(api, { path, year })

			if ('data' in response) {
				GlobalStore.onHandleSuccessMessages({ response, useDesignSystem: true })
				this.updatedAnnualDueDate = { date: response.data.updated_due_date, id }
			}
		} catch (error: any) {
			GlobalStore.onHandleErrorMessages({ error, useDesignSystem: true })
		}
	},

	async updateNotes(notesData: NotesData): Promise<void> {
		try {
			await postNotes(api,
				`/agencies/${getAgencyId()}/caregivers/add_note`,
				{
					memberId: state.memberDetails!.caregiver!.id,
					notes: notesData.notes,
				}
			)
		} catch (error) {
			console.error(error)
		}
	},
}

export default store
