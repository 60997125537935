import type { AccountNotification, ApiPagination } from '@/javascript/interfaces'

import type { ApiClient } from '../api'
import { apiRouteBuilder, putChangeCurriculumTrack } from '../apiRoutes'
import type { ComplianceType } from '../courses/interfaces'
import type { Member } from '../members/interfaces'
import type { AgencyTeam, TrainingGroup } from './interfaces'

export async function deleteTrainingGroup(api: ApiClient, agencyId: number, groupId: number): Promise<ApiResponse> {
	const response = await api.delete<ApiResponse>(`/api/agencies/${agencyId}/training_groups/${groupId}`)

	return response.data
}

export async function getAccountNotifications(api: ApiClient, agencyId: number): Promise<AccountNotification[]> {
	const { data } = await api.get<AccountNotification[]>(`/api/agencies/${agencyId}/account_notifications`)

	return data
}

export async function getAgencyTeam(api: ApiClient, agencyId: number): Promise<AgencyTeam> {
	const { data } = await api.get<AgencyTeam>(`/api/agencies/${agencyId}/training_groups`)

	return data
}

export async function getTeamComplianceDetails(api: ApiClient, agencyId: number): Promise<ApiTeamComplianceDetailsResponse> {
	const { data } = await api.get<ApiTeamComplianceDetailsResponse>(`/api/agencies/${agencyId}/team_compliance`)

	return data
}

export async function getTeamMember(api: ApiClient, payload: ApiGetMemberPayload): Promise<Member> {
	const { data } = await api.get<ApiMemberResponse>(`/api/agencies/${payload.agencyId}/team_members/${payload.memberId}`)

	return data.data
}

export async function getTeamMembers(api: ApiClient, payload: ApiGetMembersPayload): Promise<ApiMembersResponse> {

	const { data } = await api.get<ApiMembersResponse>(`/api/agencies/${payload.agency_id}/team_members`, {
		agency_id: payload.agency_id,
		group_ids: payload.group_ids,
		page: payload.page,
		per_page: payload.per_page,
		query: payload.query,
		roles: payload.roles,
		sort_by: payload.sort_by,
		sort_direction: payload.sort_direction,
		statuses: payload.statuses,
	})

	return data
}

export async function getUserSeating(api: ApiClient, agencyId: number): Promise<ApiUserSeatingResponse> {
	const { data } =
		await api.get<ApiUserSeatingResponse>(`/api/agencies/${agencyId}/user_seating`)

	return data
}

export async function postAddTrainingGroup(api: ApiClient, payload: ApiPostUpdateTrainingGroupPayload): Promise<void> {
	await api.post(`/api/agencies/${payload.agency_id}/training_groups`, payload)
}

export async function putTransferMembers(api: ApiClient, payload: ApiPutTransferMembers): Promise<void> {
	await api.put(`/api/agencies/${payload.agencyId}/training_groups/${payload.groupId}/transfer_members`,
		{ caregiver_ids: payload.memberIds }
	)
}

export async function putChangeGroupCurriculumTrack(api: ApiClient, payload: ApiPutChangeCurriculumPayload): Promise<void> {
	const route = apiRouteBuilder(putChangeCurriculumTrack, {
		agencyId: payload.agencyId,
		curriculumTrackId: payload.curriculumTrackId,
		groupId: payload.groupId,
	})

	await api.put(route, payload)
}

export async function putUpdateTrainingGroup(
	api: ApiClient,
	payload: ApiPostUpdateTrainingGroupPayload
): Promise<ApiChangeTrainingResponse> {
	const response = await api.put(`/api/agencies/${payload.agency_id}/training_groups/${payload.id}`, payload)

	return response.data as ApiChangeTrainingResponse
}

export interface ApiGetMemberPayload {
	agencyId: number
	memberId: number
}

export interface ApiFilterRequest {
	group_ids: string[]
	roles: string[]
	statuses: string[]
}

export interface ApiGetMembersPayload {
	agency_id: number
	group_ids?: string[]
	page: number | null
	per_page: number | null
	query: string
	roles?: string[]
	sort_by?: string
	sort_direction?: string
	statuses?: string[]
}

export interface ApiPostUpdateTrainingGroupPayload {
	agency_id: number
	caregiver_ids?: string[]
	curriculum_track_id?: number
	id?: number
	is_default?: boolean
	name?: string
}

export interface ApiPutChangeCurriculumPayload {
	agencyId: number
	curriculumTrackId: number
	groupId: number
}

export interface ApiPutTransferMembers {
	agencyId: number
	groupId: number
	memberIds: string[]
}

export interface ApiResponse {
	errors: string[]
	status: string
	success: string
}

export interface ApiChangeTrainingResponse extends ApiResponse {
	data: TrainingGroup
}

export interface ApiMemberResponse {
	data: Member
}

export interface ApiMembersResponse {
	data: Member[]
	pagination: ApiPagination
	status: string
}

export interface ApiUserSeatingResponse {
	exceeded_seating_limit: boolean
	franchisor_seating: boolean | null
	max_number_of_seats: number
	restrict_user_seating: boolean
	seats_used: number
	show_available_seats: boolean
}

export interface ApiTeamComplianceDetailsResponse extends ApiResponse {
	data: TeamComplianceDetails
}
export interface TeamComplianceDetails {
	compliance_types_annual: ComplianceType
	compliance_types_initial: ComplianceType
	compliance_types_none: ComplianceType
	is_specialized_certifications_feature_enabled: boolean
	waive_types_agency: ComplianceType
	waive_types_careacademy: ComplianceType
}
