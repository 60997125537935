import Vue from 'vue'

import createDialog from '@/javascript/components/dialog'
import type { Agency } from '@/javascript/interfaces'

import api from '../services/api'
import type { CurriculumTrack } from '../services/courses/interfaces'
import { deleteMemberGroup, getGroups } from '../services/groups'
import type {
	Group,
	GroupAction,
	GroupMember,
	GroupPaths,
} from '../services/groups/interfaces'
import { getAgencyId } from '../utils/getAgencyId'
import { pluralize } from '../utils/stringUtils'
import GlobalStore from './GlobalStore'

const state = Vue.observable({
	action: '' as GroupAction,
	agency: {} as Agency,
	groups: [] as Group[],
	isLoadingMembers: false,
	manageGroups: false,
	members: [] as GroupMember[] | [],
	membersSearchInput: '',
	membersTableSort: {
		column: 'name',
		order: 'ascending',
	} as SortOptions,
	paths: {} as GroupPaths,
	selectedGroup: {} as Group | undefined,
	selectedGroupId: undefined as number | undefined,
	selectedMemberId: undefined as number | undefined,
	selectedMemberIds: [] as string[],
	selectedTrack: {} as CurriculumTrack | undefined,
	selectedTrackId: undefined as number | undefined,
	showDeleteGroupDialog: false,
	sidebarHeight: 0,
	tracks: [] as CurriculumTrack[],
})

const store = {
	// Getters and Setters
	get action(): GroupAction {
		return state.action
	},
	set action(value) {
		state.action = value
	},

	get agency(): Agency {
		return state.agency
	},
	set agency(value) {
		state.agency = value
	},

	get groups(): Group[] {
		return state.groups
	},
	set groups(value) {
		state.groups = value
	},

	get isLoadingMembers(): boolean {
		return state.isLoadingMembers
	},
	set isLoadingMembers(value) {
		state.isLoadingMembers = value
	},

	get manageGroups(): boolean {
		return state.manageGroups
	},
	set manageGroups(value) {
		state.manageGroups = value
	},

	get members(): GroupMember[] {
		return state.members
	},
	set members(value) {
		state.members = value
	},

	get membersSearchInput(): string {
		return state.membersSearchInput
	},
	set membersSearchInput(value) {
		state.membersSearchInput = value
	},

	get membersTableSort(): SortOptions {
		return state.membersTableSort
	},
	set membersTableSort(value) {
		state.membersTableSort = value
	},

	get paths(): GroupPaths {
		return state.paths
	},
	set paths(value) {
		state.paths = value
	},

	get selectedGroup(): Group | undefined {
		return state.selectedGroup
	},
	set selectedGroup(value) {
		state.selectedGroup = value
	},

	get selectedGroupId(): number | undefined {
		return state.selectedGroupId
	},
	set selectedGroupId(value) {
		state.membersSearchInput = ''
		state.selectedGroupId = value
	},

	get selectedMemberId(): number | undefined {
		return state.selectedMemberId
	},
	set selectedMemberId(value) {
		state.selectedMemberId = value
	},

	get selectedMemberIds(): string[] {
		return state.selectedMemberIds
	},
	set selectedMemberIds(value) {
		state.selectedMemberIds = value
	},

	get selectedTrack(): CurriculumTrack | undefined {
		return state.selectedTrack
	},
	set selectedTrack(value) {
		state.selectedTrack = value
	},

	get selectedTrackId(): number | undefined {
		return state.selectedTrackId
	},
	set selectedTrackId(value) {
		state.selectedTrackId = value
	},

	get showDeleteGroupDialog(): boolean {
		return state.showDeleteGroupDialog
	},
	set showDeleteGroupDialog(value) {
		state.showDeleteGroupDialog = value
	},

	get sidebarHeight(): number {
		return state.sidebarHeight
	},
	set sidebarHeight(value) {
		state.sidebarHeight = value
	},

	get tracks(): CurriculumTrack[] {
		return state.tracks
	},
	set tracks(value) {
		state.tracks = value
	},

	// Actions
	addGroup(group: Group): void {
		state.groups.push({
			...group,
			members: [],
		})
		state.selectedGroupId = group.id
	},

	clearAction(): void {
		state.action = ''
		state.selectedMemberId = undefined
	},

	confirmDeleteGroup(useDesignSystem: boolean = GlobalStore.useDesignSystem): void {
		if (!state.selectedGroup) {
			GlobalStore.onHandleErrorMessages({ error: 'There is not a group selected to delete. Please try again.' })

			return
		}

		if (state.selectedGroup.is_default) {
			GlobalStore.onHandleErrorMessages({ error: 'You cannot delete your default group. Please make a different group the default and try again.' })

			return
		}

		if (!!state.members.length) {
			GlobalStore.onHandleErrorMessages({
				error: `You cannot delete a group with ${GlobalStore.teamMemberText} in it.
				Please move the ${pluralize(state.members.length, GlobalStore.teamMemberText)} to another group and try again.`,
			})

			return
		}

		this.onConfirmDialog(useDesignSystem)
	},

	onConfirmDialog(useDesignSystem?: boolean): void {
		if (!state.selectedGroup) {
			return
		}
		state.showDeleteGroupDialog = true
		if (useDesignSystem) {
			return
		}

		createDialog({
			bodyText: `Confirm that you want to delete the group: "${state.selectedGroup.name}". Any inactive
        ${GlobalStore.teamMemberText} will be assigned to default group "${state.groups[0]?.name}."`,
			confirmButtonText: 'Delete Group',
			dialogSelector: '.mdc-dialog',
			titleText: 'Are you sure you want to delete this group?',
		}).then(async () => await this.onDeleteGroup(), () => { })
	},

	deleteGroup(id: number): void {
		state.groups = state.groups ? state.groups.filter(group => id !== group.id) : []
		if (state.selectedGroupId === id) {
			state.selectedGroupId = state.groups[0].id
		}
	},

	filterMembers(): void {
		if (state.membersSearchInput && state.membersSearchInput.length) {
			if (state.selectedGroup) {
				state.selectedGroup = {
					...state.selectedGroup,
					members: state.members.filter(member => {
						return member.stringToSearch?.includes(state.membersSearchInput)
					}),
				}
			}
		} else if (state.membersSearchInput === '') {
			if (state.selectedGroup) {
				state.selectedGroup = {
					...state.selectedGroup,
					members: state.members,
				}
			}
		}
	},

	async onDeleteGroup(): Promise<void> {
		if (!state.selectedGroup) {
			return
		}

		state.showDeleteGroupDialog = false
		const groupId = state.selectedGroup.id

		try {
			await deleteMemberGroup(
				api,
				state.paths.delete,
				{ id: groupId }
			)

			this.deleteGroup(groupId)

			GlobalStore.onHandleSuccessMessages({ message: `"${state.selectedGroup.name}" group successfully deleted.` })

			state.selectedGroup = state.groups[0]
		} catch (error) {
			console.error(error)
		}
	},

	resetData(): void {
		this.selectedGroup = undefined
		this.selectedGroupId = undefined
		this.selectedMemberId = undefined
		this.selectedMemberIds = []
		this.selectedTrack = undefined
		this.selectedTrackId = undefined
	},

	async retrieveGroups(groupId?: number): Promise<void> {
		state.isLoadingMembers = true

		try {
			state.groups = await getGroups(api, getAgencyId())
			state.selectedGroupId = groupId || state.groups[0].id
			state.selectedGroup = state.groups ? state.groups
				.find(group => state.selectedGroupId === group.id) : undefined
			state.members = state.selectedGroup?.members || []

			if (state.selectedGroup) {
				this.searchableStringOnGroup(state.selectedGroup)

				state.selectedTrackId = state.selectedGroup?.curriculum_track_id
				state.selectedTrack = state.tracks ? state.tracks.find(track => {
					return state.selectedGroup && 'curriculum_track_id' in state.selectedGroup && state.selectedGroup?.curriculum_track_id === track.id
				}) : undefined
			}
		} catch (error: any) {
			console.error(error)
		} finally {
			state.isLoadingMembers = false
		}
	},

	searchableStringOnGroup(group: Group): void {
		group.members.forEach(member => {
			const str = member.name.replace(/\s+/g, '') + (member.phone || '') + member.email

			member.stringToSearch = str.toLowerCase()
		})
	},
}

interface SortOptions {
	column: string
	order: string
}

export default store
