import * as mdc from 'material-components-web'

import type { DialogOptions } from '@/javascript/interfaces'

export default function createDialog({
	bodyText,
	closeButtonText,
	confirmButtonText,
	dialogSelector,
	titleText,
}: DialogOptions): Promise<void> {
	return new Promise((resolve, reject) => {
		const element: Element | null = document.querySelector(dialogSelector)

		if (element) {
			const dialogMDC = new mdc.dialog.MDCDialog(element)

			const dialogDefaultLabel = element.querySelector('#mdc-dialog-default-label') as HTMLElement
			const dialogDefaultDescription = element.querySelector('#mdc-dialog-default-description') as HTMLElement
			const confirmButton = element.querySelector('[data-mdc-dialog-action="confirm"]') as HTMLElement
			const closeButton = element.querySelector('[data-mdc-dialog-action="close"]') as HTMLElement

			dialogDefaultLabel.innerHTML = titleText
			dialogDefaultDescription.setAttribute('data-test', 'modal-description')
			dialogDefaultDescription.innerHTML = bodyText

			if (confirmButtonText) {
				confirmButton.innerHTML = confirmButtonText
			}

			if (closeButtonText) {
				closeButton.innerHTML = closeButtonText
			}

			element.addEventListener('click', event => {
				event.stopPropagation()
			})

			confirmButton.addEventListener('click', () => {
				element.querySelector('[data-mdc-dialog-action="cancel"]')?.classList.add('hidden')
				confirmButton.setAttribute('disabled', 'disabled')
				const confirmText = confirmButton.innerHTML

				confirmButton.innerHTML = 'Please wait...'
				resolve()
				confirmButton.removeAttribute('disabled')
				confirmButton.innerHTML = confirmText
				dialogMDC.close()
			})

			dialogMDC.listen('MDCDialog:closed', () => {
				reject()
			})

			dialogMDC.open()
		}
	})
}
