import type { ActionableCourse } from '../services/courses/interfaces'
import type { HistoricalCourse } from '../services/goals/interfaces'
import type { MemberTrainingCourse, WaiveData } from '../services/memberTraining/interfaces'

export const COMPLETE = 100

export const CONFIG_HEADER = { headers: { Accept: 'application/json' } }
export const DEFAULT_SELECTED_CURRICULUM_NAME = '[Curriculum name]'
export const DELAY = Object.freeze({
	FAST: 100,
	NORMAL: 1000,
})

export const DURATION_FILTER_DEFAULT = 120
export const DURATION_FILTER_STEP = 10

export const INVALID_KEYS = ['-', 'e', '+', '.', 'ArrowUp', 'ArrowDown']

export const PAGINATION_INDEX = 1

export const PER_PAGE_DEFAULT = 100

export const PERCENTAGE_ERROR_TEXT = 'Must be a number between 0-100, or left blank'

export const SEAT_COUNT = Object.freeze({
	INCLUDED: 25,
	MAX: 1000,
	MIN: 0,
})

export const TEAM_MEMBERS_PER_PAGE = 25

export const ALERT_TIMEOUT_DEFAULT = 5000
export const TEXT_MESSAGE_DIALOGUE_DELAY = 2000
export const TIMEOUT_DEFAULT = 2000

// text field
const textFieldClass = 'mdc-text-field'
const textFieldSelector = '.mdc-text-field'
const textFieldErrorClass = 'mdc-text-field--invalid'
const textFieldErrorSelector = '.mdc-text-field--invalid'

// element hidden
const hiddenElemSelector = '.hide, .js-reveal--element-hidden, .dn'

export {
	hiddenElemSelector,
	textFieldClass,
	textFieldErrorClass,
	textFieldErrorSelector,
	textFieldSelector,
}

export function getDefaultCourse(): MemberTrainingCourse {
	return {
		add_to_library: false,
		courseStatusActions: {
			renaming: false,
			selected: false,
			type: 'courses',
		},
		course_status_id: null,
		description: '',
		id: null,
		is_external: false,
		length_in_minutes: undefined,
		limited_usage: false,
		name: '',
		topics: [],
		training: {
			completionDate: '',
			grade: undefined,
			location: '',
			organization: '',
			trainer: '',
		},
		type: '',
	}
}

export function getDefaultHistoricalCourse(): HistoricalCourse {
	return {
		added_in_bundle: false,
		caregiver_id: null,
		course_id: null,
		course_status_id: null,
		description: '',
		external_courses: [],
		goal: {
			due_date: '',
			is_new: false,
			saveClassList: false,
		},
		id: null,
		name: '',
		training: { organization: '' },
		type: undefined,
	}
}

export function getDefaultWaiveClass(): ActionableCourse {
	return {
		add_to_library: false,
		agency_id: 0,
		caregiver_ids: '',
		course: {
			description: '',
			finalGrade: undefined,
			name: '',
			training: {
				completedDate: '',
				date: '',
				grade: undefined,
				location: '',
				trainer: '',
			},
			type: 'none',
		},
		course_status_id: null,
	}
}

export function getDefaultWaiveData(): WaiveData {
	return {
		action: 'waive_class',
		caregiverId: null,
		courseData: {
			courseDesc: '',
			courseDuration: 60,
			isLmsCourse: false,
			tippyContent: '',
		},

		courseName: '',
		courseStatusId: null,
		goalHasComplianceMandates: false,
		is_external: false,
		test: '',
		training: {
			completionDate: '',
			location: '',
			organization: '',
			trainer: '',
		},

		type: 'online_careacademy',
	}
}
