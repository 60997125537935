import { add } from 'date-fns'

import { standardDateFormat } from '@/javascript/lib/validate_date/process-date-string'

export const formatRelativeLocale = {
	lastWeek: '\'last\' eeee',
	nextWeek: '\'next\' eeee',
	other: 'MMMM do, yyyy',
	today: '\'today\'',
	tomorrow: '\'tomorrow\'',
	yesterday: '\'yesterday\'',
}

/**
 * Generate a random string to append where needed
 *
 * @example appendRandomString() // "1234567890"
 * The current time is impossible to duplicate, but when multiple fields load
 * at the same time, there can be duplicates, so we will add a Math.random as well
 * @returns {string}
 */

// This function was mainly created to help prevent autocompletion from Chrome. https://gist.github.com/niksumeiko/360164708c3b326bd1c8#gistcomment-3719706
export const appendRandomString = (): string => {
	const hash = Math.random().toString(20).substr(2, 8)

	return hash + Date.now()
}

/**
 * Capitalize first letter in a string:
 *
 * @example capitalize('please try again!') // Please try again!
 * @param {string} value
 * @returns {string}
 */
export const capitalize = (value = ''): string => {
	return value.length > 0 ? `${value.charAt(0).toUpperCase()}${value.slice(1)}` : value
}

/**
 * Converts date based on locale, defaults to 'en-US':
 *
 * @example convertDateStringToUS('1999-1-1') // 1/1/1999
 * @param {string} date
 * @returns {string}
 */
export const convertDateStringLocale = (date = '', locale = 'en-US'): string => {
	return date ? new Date(date).toLocaleDateString(locale) : ''
}

/**
 * Converts minutes to hours
 *
 * @param {number} minutes
 * @returns {string}
 */
export const convertMinutesToHours = (minutes: number | null): string => {
	if (!minutes) {
		return '0 hours'
	}

	const reminder = minutes % 60
	const suffix = `${!!reminder ? '+' : ''} hour${minutes / 60 > 1 ? 's' : ''}`

	return `${Math.floor(minutes / 60)}${suffix}`
}

/**
 * Format date by weeks ago:
 *
 * @example formatDateByWeek(-5) // Sets date 5 weeks from current date
 * @param {number} weeks
 * @returns {string}
 */
export const formatDateByWeek = (weeks = 0): string => {
	const date = new Date()

	return standardDateFormat(add(date, { 'weeks': weeks }))
}

/**
 * Formats phoneNumber string into North American phone number format:
 *
 * @example formatPhoneNumber('1234567890') // "(123) 456-7890"
 *          formatPhoneNumber('+11234567890') // "+1 (123) 456-7890"
 * @param {string} phoneNumber - where phoneNumber.length >= 10
 * @returns {string}
 */
export const formatPhoneNumber = (phoneNumber: string): string | null => {
	const cleaned = `${phoneNumber}`.replace(/\D/g, '')
	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

	if (match) {
		const intlCode = match[1] ? '+1 ' : ''

		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
	}
	return null
}

/**
 * Returns the initials of a name:
 *
 * @example getInitials('John Doe') // JD
 *
 * @param {string} name
 * @returns {string}
 */
export const getInitials = (name: string): string => {
	return name.split(' ')[0].charAt(0).toUpperCase() + name.split(' ')[1]?.charAt(0).toUpperCase()
}

/**
 * Returns the plural or singular form of a term based on a number:
 *
 * @example pluralize(2, 'member') // 2 members
 *
 * @param {number} count
 * @param {string} term
 *
 * @returns {string}
 */
export function pluralize(count: number, term: string): string {
	if (term.toLowerCase().endsWith('s'.toLowerCase())) {
		return `${term}${count > 1 ? 'es' : ''}`
	}

	return `${term}${count !== 1 ? 's' : ''}`
}

/**
 * Returns the string with the replaced text:
 * @example replaceText('remove', '', 'removeMember') // 2 members
 *
 * @param {string} replaceText
 * @param {string} replaceTextWith
 * @param {string} text
 *
 * @returns {string}
 */
export function replaceText(replaceText: string, replaceTextWith: string, text: string): string {
	return text.replace(replaceText, replaceTextWith)
}
